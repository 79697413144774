import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';

// Retailers with hubspot chatbot widget integration
const WidgetData: Record<
  string,
  { widgetQueryParam: string; widgetParamValue: string }
> = {
  'Target': {
    widgetQueryParam: 'target_widget_init',
    widgetParamValue: '1',
  },
  'Ulta Beauty': {
    widgetQueryParam: 'ulta_widget_init',
    widgetParamValue: '1',
  },
};

type HubSpotConversationsWidget = {
  widget: {
    refresh: () => void;
    remove: () => void;
  };
};

declare global {
  interface Window {
    HubSpotConversations?: HubSpotConversationsWidget;
  }
}

/**
 * RetailerHubSpotWidget
 *
 * This component integrates specific HubSpot chatflows that gets activated
 * only for users in specifically registered retailer portals as defined by the above WidgetData. It appends a query string
 * parameter (e.g. `target_widget_init=1`) to the URL when the brand's retailer portal name
 * is detected as the correct retailer (e.g. 'Target'). This query string triggers the HubSpot chat widget
 * to pull from the correlated Retailer HubSpot ChatFlow, ensuring users have access to
 * retailer-specific support through the Novi portal.
 *
 * ### Dependency on `useHubSpot`
 * This component relies on the `useHubSpot` hook to load the HubSpot conversations
 * widget. Specifically, the `useHubSpot` hook's `loadVerifiedWidget` function is
 * responsible for calling `window.HubSpotConversations.widget.load()` which is required
 * for the widget to function. Without this call, you may encounter the following error:
 *
 * ```
 * loadImmediately is set to false and widget.load() has not been called on
 * window.HubSpotConversations yet. Please call widget.load() first or set
 * loadImmediately on window.hsConversationsSettings to true.
 * ```
 *
 * This is specific to the specific retailer's brand portals and does not reuse
 * the generic HubSpot widget setup in `useHubSpot.js`, which handles general
 * HubSpot integrations across different contexts.
 *
 * The widget will not activate or add the query parameter if the retailer is
 * not one of the registered retailers. The effect is triggered on page load.
 *
 * @returns {null} - This component does not render anything on the page.
 */
export default function RetailerHubSpotWidget() {
  const { retailerBrand } = useApp();
  const history = useHistory();
  const location = useLocation();

  const loadRetailerWidget = (
    retailerName: string,
    hubspotConversationsWidget: HubSpotConversationsWidget
  ) => {
    const searchParams = new URLSearchParams(location.search);
    // Remove all widget query params from the URL (if any, thus clearing unrelated params)
    for (const retailer in WidgetData) {
      searchParams.delete(WidgetData[retailer].widgetQueryParam);
    }

    // Find if current retailer has hubspot chatbot integration.
    const retailerWidgetName = Object.keys(WidgetData).find(
      (retailer) => retailer === retailerName
    );

    if (retailerWidgetName) {
      const { widgetQueryParam, widgetParamValue } = WidgetData[retailerName];
      searchParams.set(widgetQueryParam, widgetParamValue);
      history.push({ search: searchParams.toString() });
      hubspotConversationsWidget.widget.refresh();
    } else {
      removeRetailerWidget();
    }
  };

  const removeRetailerWidget = () => {
    const searchParams = new URLSearchParams(location.search);
    for (const retailer in WidgetData) {
      searchParams.delete(WidgetData[retailer].widgetQueryParam);
    }
    history.push({ search: searchParams.toString() });
    window.HubSpotConversations?.widget.remove();
  };

  useEffect(() => {
    if (!retailerBrand?.retailer?.name) return;
    if (!window.HubSpotConversations) return;
    if (location.pathname.includes(PATHS.portalSelection)) {
      removeRetailerWidget();
      return;
    }

    loadRetailerWidget(
      retailerBrand.retailer.name,
      window.HubSpotConversations
    );
  }, [
    retailerBrand?.retailer?.name,
    window.HubSpotConversations,
    location.pathname,
  ]);

  return null;
}
