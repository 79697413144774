// @ts-nocheck
import {
  TabsAndTable,
  TabGroup,
  Tab,
  Toggle,
  Pill,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableFoot,
  TableBodyCell,
  PaginationV2,
  NoContent,
  NoData,
  NoDataVariants,
  FilterModal,
  FilterModalTypes,
  SearchFilter,
} from 'design-system/components';
import { FilterReducerAction } from 'design-system/utils';

import PolicyRequirementRow from './PolicyRequirementRow';
import { VIEW_ALL_NAME, SORT_OPTIONS } from '../data/constants';
import styles from '../policyRequirements.module.scss';

const PolicyRequirementsTabsAndTable = ({
  tabData,
  filterState,
  filterStateDispatch,
  currentFilteredData,
  tableHeaderData,
  activeSort,
  setActiveSort,
  setSelectedRequirement,
  setModalOpen,
  setCurrentRow,
  modalNavPrevLogic,
  modalNavNextLogic,
  baseUrl,
  requirementGroupId,
  requirementId,
  loading,
  error,
  hasLoadedSuccessfully,
  hasData,
  showSubcategoryToggleRow,
  subcategoryFilterOptionsWithAll,
  showFilterModalButton,
  filters,
  page,
  setPage,
  data,
  activeTabIndex,
  setActiveTabIndex,
  setActiveTab,
  currentRow,
  modalOpen,
}) => (
  <TabsAndTable
    tabs={
      <TabGroup
        activeTabIndex={activeTabIndex}
        ariaLabelledBy="tabs-and-filter-header-subtitle"
        additionalHeader={
          <SearchFilter
            callback={() => setCurrentRow(null)}
            sortOptions={SORT_OPTIONS}
          />
        }
      >
        {tabData.map((tab, index) => (
          <Tab
            key={tab.value}
            chipValue={tab.quantity || 0}
            isActive={activeTabIndex === index}
            onClick={() => {
              setActiveTabIndex(index);
              setActiveTab(tab.value);
              setCurrentRow(null);
            }}
            pillColor={tab.pillColor}
          >
            {tab.label}
          </Tab>
        ))}
      </TabGroup>
    }
    toggle={
      showSubcategoryToggleRow || showFilterModalButton ? (
        <div className={styles['toggle-row']}>
          {showSubcategoryToggleRow && (
            <Toggle
              options={subcategoryFilterOptionsWithAll.map(
                (option) => option.name
              )}
              secondaryItems={subcategoryFilterOptionsWithAll.map((option) => (
                <Pill key={option.id}>{option.count}</Pill>
              ))}
              activeOption={
                (filterState.subcategories &&
                  filterState.subcategories[0]?.name) ||
                VIEW_ALL_NAME
              }
              onClick={(optionName) => {
                switch (optionName) {
                  case VIEW_ALL_NAME:
                    filterStateDispatch({
                      type: FilterReducerAction.ClearFilters,
                    });
                    return;
                  default:
                    filterStateDispatch({
                      type: FilterReducerAction.ApplyFilters,
                      filters: {
                        subcategories: [
                          subcategoryFilterOptionsWithAll.find(
                            (option) => option.name === optionName
                          ),
                        ],
                      },
                    });
                }
                setCurrentRow(null);
              }}
              variant="outlined"
            />
          )}
          {showFilterModalButton && (
            <FilterModal
              filters={filters}
              state={filterState}
              onModalClose={(data) => data && filterStateDispatch(data)}
              dataCyRoot="policy-requirements-filter"
              onFetch={() => {}}
              type={FilterModalTypes.Dropdown}
            />
          )}
        </div>
      ) : null
    }
    table={
      <div>
        {/* LOADING STATE */}
        {loading && !error && <NoContent loading />}
        {/* ERROR STATE */}
        {error && (
          <NoData
            hasErrorOccurred={!!error}
            noContentMessage="Something went wrong. Please try again or contact support."
          />
        )}
        {/* NO DATA STATE */}
        {hasLoadedSuccessfully && !hasData && (
          <NoContent variant={NoDataVariants.Default} />
        )}
        {/* HYDRATED TABLE */}
        {hasLoadedSuccessfully && hasData && (
          <div className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaderData?.map((data) => (
                    <TableHeadCell key={data.value}>{data.label}</TableHeadCell>
                  ))}
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {currentFilteredData.map((requirement, i) => (
                  <PolicyRequirementRow
                    key={i}
                    isActive={currentRow === i + 1}
                    modalOpen={modalOpen}
                    requirement={requirement}
                    onClick={() => {
                      setSelectedRequirement(requirement);
                      setModalOpen(true);
                      setCurrentRow(i + 1);
                      modalNavPrevLogic(i);
                      modalNavNextLogic(i);
                    }}
                    url={`${baseUrl}/${requirementGroupId}/retailer_requirements/${requirementId}/policy_requirements/${requirement.id}/summary`}
                  />
                ))}
              </TableBody>
              <TableFoot bgWhite>
                <TableRow>
                  <TableBodyCell colSpan={4}>
                    <PaginationV2
                      onNextPage={() => setPage(page + 1)}
                      onPreviousPage={() => setPage(page - 1)}
                      onPageSelect={setPage}
                      currentPage={data?.page}
                      totalItemCount={data?.count}
                      itemsPerPage={data?.items}
                    />
                  </TableBodyCell>
                </TableRow>
              </TableFoot>
            </Table>
          </div>
        )}
      </div>
    }
  />
);

export default PolicyRequirementsTabsAndTable;
