import cn from 'classnames';
import {
  GridItem,
  Link,
  LinkKind,
  LinkVariant,
  NoContent,
  NoDataVariants,
  PhosphorIcon,
  Progress,
  ProgressSize,
  ProgressVariant,
  Text,
  TextKind,
  TextElement,
  Avatar,
  AvatarKind,
} from 'design-system/components';
import { PATHS } from 'constants/index';
import withLineBreaks from 'utils/withLineBreaks';

import styles from './retailerBrandDashboard.module.scss';
import { Key } from 'react';
import { PolicyProps } from 'views/Brands/RetailerBrandEligibility/PolicySelectionPage/PolicyCard/types';

const PillarCardsSection = ({
  user,
  retailerPolicies,
  loading,
  error,
}: {
  user: any;
  retailerPolicies: { policies: PolicyProps[] };
  loading: boolean;
  error: any;
}) => {
  const noPolicyData = !retailerPolicies?.policies.length && !loading && !error;
  const hasNonEmptyRequirements = (requirementGroup: {
    totalRequirements: number;
  }) => requirementGroup.totalRequirements !== 0;
  const nonEmptyFilteredPolicies = retailerPolicies?.policies.filter((policy) =>
    policy.retailerPolicyRequirementGroups.some(hasNonEmptyRequirements)
  );

  return (
    <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
      <div className={styles['retailer-policy-groups']}>
        {retailerPolicies?.policies &&
          retailerPolicies.policies.length > 0 &&
          nonEmptyFilteredPolicies.map((policy: PolicyProps, i: Key) => {
            const hasManyPillars =
              policy.retailerPolicyRequirementGroups.length > 1;
            return (
              <div className={styles.policy} key={i}>
                <Text kind={TextKind.DisplayXSMedium} element={TextElement.H2}>
                  {policy.name && `${policy.name} `}Pillars
                </Text>
                <div className={styles['group-container']}>
                  {policy.retailerPolicyRequirementGroups
                    .filter(hasNonEmptyRequirements)
                    .map((requirementGroup, i) => {
                      return (
                        <div
                          className={cn([
                            styles['requirement-group'],
                            !hasManyPillars ? styles.ring : styles.bar,
                          ])}
                          key={i}
                        >
                          <div className={styles['group-content']}>
                            {!hasManyPillars && (
                              <Progress
                                variant={ProgressVariant.Ring}
                                size={ProgressSize.Lg}
                                percentage={requirementGroup.percentComplete}
                                label="Complete"
                              />
                            )}
                            <div>
                              <div className={styles.title}>
                                {requirementGroup.imagePath && (
                                  <Avatar
                                    initials={requirementGroup.name}
                                    name={requirementGroup.name}
                                    kind={AvatarKind.Large}
                                    src={requirementGroup.imagePath}
                                  />
                                )}
                                <Text
                                  kind={TextKind.Display2XS}
                                  element={TextElement.H3}
                                >
                                  {requirementGroup.name}
                                </Text>
                              </div>
                              <Text
                                kind={TextKind.TextSM}
                                element={TextElement.P}
                              >
                                {withLineBreaks(requirementGroup.description)}
                              </Text>
                            </div>
                          </div>
                          <div className={styles['percent-review-block']}>
                            {hasManyPillars && (
                              <Progress
                                variant={ProgressVariant.Bar}
                                percentage={requirementGroup.percentComplete}
                              />
                            )}
                            <Link
                              kind={LinkKind.Internal}
                              variant={LinkVariant.NewDefault}
                              to={`requirement_groups/${requirementGroup.id}/retailer_requirements`}
                            >
                              Review
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        {loading && <NoContent loading />}
        {noPolicyData && (
          <NoContent variant={NoDataVariants.Default}>
            {user.hasFF('upload_skus') && (
              <Link
                kind={LinkKind.Internal}
                variant={LinkVariant.Alternate}
                to={PATHS.retailerBrandProducts}
              >
                Upload your SKUs <PhosphorIcon iconName="ArrowRight" />
              </Link>
            )}
          </NoContent>
        )}
      </div>
    </GridItem>
  );
};

export default PillarCardsSection;
