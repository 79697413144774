import { FC, useEffect, createRef } from 'react';
import { DocumentsFeedbackTableRowProps } from './types';
import {
  Button,
  ButtonKind,
  ButtonSize,
  TableRow,
  TableBodyCell,
  PhosphorIcon,
  Pill,
  PillColor,
  Tooltip,
  OpenAxis,
  TextKind,
  Text,
  TextElement,
} from 'design-system/components';
import ScreeningStatusPill from 'components/ScreeningStatusPill';
import { ScreeningStatus } from 'design-system/data';
import { downloadFile } from 'design-system/utils';
import styles from '../retailerBrandSubmissions.module.scss';

const maximumNameCharacters = 20;

const truncatedText = (dataText: string, charLength: number) => {
  const text = dataText;
  if (typeof text !== 'string') return text;
  return text?.length > charLength ? `${text.slice(0, charLength)}...` : text;
};

const getIcon = (name: string): { iconName: string; title: string } => {
  const ext = name.split('.')[1];
  switch (ext) {
    case 'png':
      return { iconName: 'FilePng', title: 'file-png' };
    case 'pdf':
      return { iconName: 'FilePdf', title: 'file-pdf' };
    default:
      return { iconName: 'File', title: 'file' };
  }
};

const getStatusPillText = (status: string): string => {
  switch (status) {
    case 'accepted':
    case 'meets':
    case 'pass':
      return ScreeningStatus.Pass;
    case 'fail':
    case 'rejected':
      return ScreeningStatus.Rejected;
    case 'pending':
    case 'needs-information':
    default:
      // this could all be default but I want to be explicit about it
      return ScreeningStatus.Pending;
  }
};

const DocumentsFeedbackTableRow: FC<DocumentsFeedbackTableRowProps> = ({
  rowData,
  index,
  onClick,
  isActive,
  modalOpen,
}: DocumentsFeedbackTableRowProps) => {
  const buttonRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    if (isActive && !modalOpen) {
      buttonRef?.current?.focus();
    } else {
      buttonRef?.current?.blur();
    }
  }, [isActive, modalOpen]);

  return (
    <TableRow
      key={`${index}-${rowData.fileName}`}
      className={isActive ? styles.active : ''}
    >
      <TableBodyCell>
        <div className={styles['filename-container']}>
          <PhosphorIcon {...getIcon(rowData.fileName || '')} size="40" />
          {rowData.fileName}
        </div>
      </TableBodyCell>
      <TableBodyCell>{rowData.requirementName}</TableBodyCell>
      <TableBodyCell>
        <Pill color={PillColor.Gray}>{rowData.pillarName}</Pill>
      </TableBodyCell>
      <TableBodyCell>{rowData.skuName}</TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill
          withDot
          status={getStatusPillText(rowData.requirementStatus)}
        />
      </TableBodyCell>
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
              {truncatedText(rowData.retailerNotes, maximumNameCharacters)}
            </Text>
          }
          disabled={rowData.retailerNotes?.length <= maximumNameCharacters}
        >
          {rowData.retailerNotes}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <div className={styles['cta-container']}>
          <Button
            kind={ButtonKind.Alternate}
            size={ButtonSize.Small}
            onClick={onClick}
            refProp={buttonRef}
          >
            <div className={styles.cta}>
              View details
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowRight" size={18} />
              </span>
            </div>
          </Button>
        </div>
      </TableBodyCell>
      <TableBodyCell>
        {rowData.fileUrl && rowData.fileName && (
          <Button
            kind={ButtonKind.Link}
            onClick={(e) => {
              e.preventDefault();
              downloadFile({
                path: rowData.fileUrl as string,
                name: rowData.fileName as string,
              });
            }}
            size={ButtonSize.Large}
            className={styles['download-button']}
          >
            <PhosphorIcon iconName="DownloadSimple" size="24" />
          </Button>
        )}
      </TableBodyCell>
    </TableRow>
  );
};

export default DocumentsFeedbackTableRow;
