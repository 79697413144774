// Demo Link
export const DEMO_LINK =
  'https://calendly.com/haley-noviconnect/30min?month=2025-01';

/* ROUTES */
export const ADMIN_PATH = '/admin';
export const BRAND_PATH = '/brand';
export const SUPPLIER_PATH = '/supplier';
export const RETAILER_PATH = '/retailer';
export const PORTAL_PATH = '/portal';
export const RETAILER_BRAND_PATH = '/retailer-brand';
export const FDA_REGISTRATION_PATH = '/fda-registration';
export const OPEN_PATH = '/_';
export const PUBLIC_PATH = '/public';
export const PATHS = {
  base: '/',
  brandConditions: `${BRAND_PATH}/conditions`,
  product_marketplace: `${BRAND_PATH}/discover`,
  product_marketplace_detail: `${BRAND_PATH}/discover/:product_type/:productId`,
  checkout_status: `/checkout/status/:status`,
  checkout_cart: `/checkout`,
  ingredient_search: `${BRAND_PATH}/INCI-screening`,
  public_ingredient_search: `${PUBLIC_PATH}/ingredient-screening`,
  test_sprockets: `/test_sprockets/:productId`,
  brandProfileSettings: `${ADMIN_PATH}/brand-profile-settings`,
  userSettings: `${ADMIN_PATH}/user-settings`,
  pricingChart: `/pricing/chart`,
  pricingSuccess: `/pricing/upgrade/:status`,
  referrals: `/referrals`,
  signIn: `${OPEN_PATH}/sign-in`,
  signOut: `${OPEN_PATH}/sign-out`,
  signUp: `${OPEN_PATH}/sign-up`,
  mocraSignup: `${OPEN_PATH}/mocra-signup`,
  loginSignup: `${OPEN_PATH}/login-signup`,
  tos: `${OPEN_PATH}/accept-tos`,
  purchaseOrders: `/purchase-orders`,
  ingredientRequests: `/ingredient-requests`,
  projectDashboard: `/project/dashboard`,
  formulatorDashboard: '/projects',

  // V1 Retailer Paths
  retailerDocumentsView: `${RETAILER_PATH}/documents`,
  // Consider removing these V1 difficult to reach retailer paths and deprecating associated views.
  retailerBrandDetailsView: `${RETAILER_PATH}/brand`,
  retailerBrandFormulasView: `${RETAILER_PATH}/brand/:brand_id/formulas`,
  retailerBrandPackagingView: `${RETAILER_PATH}/brand/:brand_id/packaging`,
  retailerBrandDocumentsView: `${RETAILER_PATH}/brand/:brand_id/documents`,
  retailerProductsView: `${RETAILER_PATH}/products`,
  retailerProductDetailsView: `${RETAILER_PATH}/product/:product_id`,
  retailerProductDetailsFormulasView: `${RETAILER_PATH}/product/:product_id/formulas`,
  retailerProductDetailsPackagingView: `${RETAILER_PATH}/product/:product_id/packaging`,
  retailerProductDetailsDocumentsView: `${RETAILER_PATH}/product/:product_id/documents`,
  // V2 Retailer Paths
  retailerDashboard: `${RETAILER_PATH}/dashboard`,
  retailerReports: `${RETAILER_PATH}/reports`,
  retailerBrandsMetrics: `${RETAILER_PATH}/brands/metrics`,
  retailerBrandsSubmissions: `${RETAILER_PATH}/brands/submissions`,
  retailerBrandSubmissionPillar: `${RETAILER_PATH}/brands/submissions/:brand_id/requirement_groups`,
  retailerBrandSubmissionSKUs: `${RETAILER_PATH}/brands/submissions/:brand_id/policy/:policy_id/sku_level_status`,
  retailerBrandSubmissionSKUReview: `${RETAILER_PATH}/brands/submissions/:brand_id/policy/:policy_id/sku_level_status/:product_id/requirements`,
  retailerSkuMetrics: `${RETAILER_PATH}/skus/metrics`,
  retailerAdditionalReports: `${RETAILER_PATH}/additional-reports`,
  retailerAdditionalReportsDetail: `${RETAILER_PATH}/additional-reports/:id`,

  // Portal Paths
  portalSelection: `${PORTAL_PATH}/selection`,

  // Brand Paths
  brandProductView: `${BRAND_PATH}/:brand_id/product/:product_id`,
  brandDocumentsView: `${BRAND_PATH}/:brand_id/documents`,
  brandYourProductsView: `${BRAND_PATH}/:brand_id/products`,
  retailerBrandWelcome: `${RETAILER_BRAND_PATH}/welcome`,
  retailerBrandDash: `${RETAILER_BRAND_PATH}/dashboard`,

  retailerBrandRequirementGroups: `${RETAILER_BRAND_PATH}/requirement_groups`,
  retailerBrandRequirementGroupsDetail: `${RETAILER_BRAND_PATH}/requirement_groups/:requirement_group_id/retailer_requirements`,
  retailerBrandRequirementPolicies: `${RETAILER_BRAND_PATH}/requirement_groups/:requirement_group_id/retailer_requirements/:requirement_id/policy_requirements`,
  retailerBrandRequirementSummary: `${RETAILER_BRAND_PATH}/requirement_groups/:requirement_group_id/retailer_requirements/:requirement_id/policy_requirements/:policy_id/summary`,

  // Retailer Brand Eligibility paths
  retailerBrandEligibility: `${RETAILER_BRAND_PATH}/eligibility`,
  retailerBrandSKULevelStatus: `${RETAILER_BRAND_PATH}/eligibility/:policy_id/sku_level_status`,
  retailerBrandSKURequirements: `${RETAILER_BRAND_PATH}/eligibility/:policy_id/sku_level_status/:product_id/requirements`,

  retailerBrandDocuments: `${RETAILER_BRAND_PATH}/documents`,
  retailerBrandProducts: `${RETAILER_BRAND_PATH}/skus`,
  retailerBrandProductCreate: `${RETAILER_BRAND_PATH}/skus/create`,
  retailerBrandSubmissions: `${RETAILER_BRAND_PATH}/submissions`,

  // Old-style Retailer Brand Paths (TODO: remove if/when we're okay breaking links that may be saved)
  retailerBrandWelcomeWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/welcome`,
  retailerBrandDashWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/dashboard`,

  retailerBrandRequirementGroupsWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/requirement_groups`,
  retailerBrandRequirementGroupsDetailWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/requirement_groups/:requirement_group_id/retailer_requirements`,
  retailerBrandRequirementPoliciesWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/requirement_groups/:requirement_group_id/retailer_requirements/:requirement_id/policy_requirements`,
  retailerBrandRequirementSummaryWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/requirement_groups/:requirement_group_id/retailer_requirements/:requirement_id/policy_requirements/:policy_id/summary`,

  retailerBrandDocumentsWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/documents`,
  retailerBrandProductsWithId: `${RETAILER_BRAND_PATH}/:retailer_brand_id/skus`,
  retailerBrandSubmissionsWithId: `${RETAILER_BRAND_PATH}/submissions/:policy_id`,

  // Supplier 2.0 Paths
  supplierListings: `${SUPPLIER_PATH}/listings`,
  supplierMaterial: `${SUPPLIER_PATH}/material`,
  supplierMaterialId: `${SUPPLIER_PATH}/material/:materialId`,
  supplierMaterialPolicies: `${SUPPLIER_PATH}/material/:materialId/policies`,

  // FDA Registration
  fdaRegistrationWelcome: `${FDA_REGISTRATION_PATH}/welcome`,
  fdaRegistrationDashboard: `${FDA_REGISTRATION_PATH}/dashboard`,
  fdaRegistrationBusiness: `${FDA_REGISTRATION_PATH}/business-registration`,
  fdaRegistrationProduct: `${FDA_REGISTRATION_PATH}/product-registration`,
  fdaRegistrationProductDetail: `${FDA_REGISTRATION_PATH}/product-registration/:product_id`,
  fdaRegistrationSubmissions: `${FDA_REGISTRATION_PATH}/submissions`,
  fdaRegistrationSubmissionDetail: `${FDA_REGISTRATION_PATH}/submissions/:submissionId`,
  fdaRegistrationSubmissionConfirmation: `${FDA_REGISTRATION_PATH}/product-registration/submission-confirmation`,

  // Sephora nonsense
  sephoraConditions: `${BRAND_PATH}/:brandId/conditions`,
  sephoraGreyIngredients: '/sephora/grey-ingredients',
  sephoraBannedList: '/sephora/banned-list',
  sephoraBrandDashboard: '/brand/sephora-dashboard',

  // External CTA links
  CM_CTA_URL: 'https://noviconnect.typeform.com/to/GlazoMQ3',
  NEW_PROJECT_CTA_URL: 'https://noviconnect.typeform.com/to/G1MkKN5P',
};

export const POLICY_CATEGORIES = {
  ANIMAL: 'Animal',
  ENVIRONMENT: 'Environment',
  HEALTH: 'Health',
  RETAILER_LIST: 'Retailer List',
  SOCIAL: 'Social',
  PACKAGING: 'Packaging',
  INTERNATIONAL_MARKETS: 'International Markets',
  PRODUCT_CERTIFICATIONS: 'Product Certifications',
};

export const VETTING_STATUS = {
  FAIL: 'fail',
  MISSING: 'missing',
  NA: 'na',
  NOTAPPLICABLE: 'not_applicable',
  PASS: 'pass',
  PROCESSING: 'processing',
  RESTRICTED: 'restricted',
  RESTRICTED_ONLY: 'restriction_only',
  NEEDS_REVISION: 'needs_revision',
  SUPPLIER_FAIL: 'supplier_fail',
  NEEDS_INFORMATION: 'needs_information',
  UPDATING: 'updating',
  //user reported statuses
  SELF_PASS: 'self_pass',
  NEEDS_POLICY: 'needs_policy',
};

// ORDER OF STATUSES IS REFLECTED IN UI
export const SUPPLIER_VETTING_STATUS = {
  PASS: 'pass',
  UNSETTLED: 'unsettled',
  FAILED: 'failed',
};

export const VETTING_STATUS_ICON_PROPS = {
  [VETTING_STATUS.PASS]: {
    icon: 'ProductStateMeets',
    fill: 'success.main',
  },
  default: {
    icon: 'ProductStateDoesNotMeet',
    fill: '#DBDBDB',
  },
};

export const INGREDIENTS_TABLE_COLUMNS = [
  {
    id: 'status',
    align: 'left',
    label: 'Status',
  },
  {
    id: 'ingredientInfo',
    align: 'left',
    label: 'Ingredient',
  },
  {
    id: 'secureTradeName',
    align: 'left',
    label: 'Trade name',
  },
  {
    id: 'secureSupplierName',
    align: 'left',
    label: 'Supplier name',
  },
  {
    id: 'securePercentage',
    align: 'right',
    label: '% in formula',
  },
  {
    id: 'actionIngredientFunction',
    align: 'left',
    label: 'Primary function',
  },

  {
    id: 'notes',
    align: 'center',
    label: 'Notes',
  },
  {
    id: 'alternatives',
    align: 'center',
    label: 'Search ingredients',
  },
  {
    id: 'actions',
    align: 'center',
    label: 'Actions',
  },
];

export const NOT_AVAILABLE_LABEL = 'Not available';

export const ROLES = {
  admin: 'admin',
  brand: 'brand',
  contractManufacturer: 'contract_manufacturer',
  noviInternal: 'novi_internal',
  supplier: 'supplier',
  formulator: 'formulator',
  retailer: 'retailer',
};

export const ORGANIZATION_TYPES = {
  'Supplier': 'supplier',
  'ContractManufacturer': 'contract_manufacturer',
  'ConsumerBrand': 'brand',
  'Formulator': 'formulator',
  'Retailer': 'retailer',
};

export const TOS_DOC = 'https://core.noviconnect.com/tos/tos.html';

export const ERROR_STATUS = {
  NOT_FOUND: {
    title: 'Page Not Found',
    description:
      "Please check the web address is correct. Press the 'Return to Website' button to visit the home page.",
  },
  SERVER_ERROR: {
    title: 'Oops Something went wrong',
    description:
      "The server encountered something unexpected that didn't allow it to complete the request.",
  },
};

export const URL_APP_FILES = {
  uploadTemplate:
    'https://ui.noviconnect.com/templates/ingredient_upload_template.csv',
};

export const SECURE_FIELD_STATUS = {
  MISSING: 'MISSING',
  LOCKED: 'LOCKED',
  VISIBLE: 'VISIBLE',
  NOTVISIBLE: 'NOT_VISIBLE',
  NOTPREVIEWABLE: 'NOT_PREVIEW',
};

export const ADD_DOCUMENTS_TYPE = {
  PRODUCT: 0,
  FORMULA: 1,
  MATERIAL: 2,
};

export const FORM_REQUIRED_ERRORS = {
  common: {
    selectOneOption: 'Please, select al least one option',
  },
  DocumentsUpload: {
    selectOneProduct: 'Please, select at least one product',
    selectDocumentType: 'Please, select at least one document type',
  },
  UploadNewProductFiles: {
    missedFiles: 'Please, add at least one file',
    moreThanOneFile: 'We only accept one file at time',
  },
};

export const NOTIFICATIONS = {
  policyNoticeNotifcation: 'notification_requires_policy_notice',
};

export const MARKETPLACE_PRODUCT_TYPE = {
  all: 'all',
  packaging: 'primary-packaging',
  formula: 'stock-formula',
  fragance: 'fragance',
};
