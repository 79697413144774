import cn from 'classnames';
import {
  Link,
  LinkVariant,
  LinkKind,
  GridItem,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';

import magnifyingGlassSvg from 'assets/illustrations/magnifying-glass.svg';
import links from 'constants/links';
import styles from './retailerBrandDashboard.module.scss';

const FooterRow = ({ retailerBrand }: { retailerBrand: any }) => {
  return (
    <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
      <div className={styles['dash-footer']}>
        <div className={cn([styles.resources, styles.panel])}>
          <img
            src={magnifyingGlassSvg}
            alt="magnifying glass"
            onError={() => <span className={styles.fallback} />}
          />
          <div>
            <div className={styles.xl}>
              <Text kind={TextKind.Display2XSMedium} element={TextElement.H3}>
                Can&apos;t find what you&apos;re looking for?
              </Text>
            </div>
            <div className={styles['three-xl']}>
              <Link
                kind={LinkKind.External}
                variant={LinkVariant.NewDefault}
                href={retailerBrand?.retailer?.support_url || links.helpDesk}
                target="_blank"
              >
                Visit the resource hub
              </Link>
            </div>
            <Link
              variant={LinkVariant.Primary}
              kind={LinkKind.External}
              href={
                retailerBrand?.retailer?.support_email
                  ? `mailto:${retailerBrand.retailer.support_email}`
                  : links.mailtoSupport
              }
            >
              Contact Novi Support
            </Link>
          </div>
        </div>
        <div className={cn([styles.explore, styles.panel])}>
          <div className={styles['three-xl']}>
            <Text kind={TextKind.Display2XSMedium} element={TextElement.H3}>
              Explore more on Novi
            </Text>
          </div>
          <div className={styles.md}>
            <Link
              kind={LinkKind.Internal}
              variant={LinkVariant.NewDefault}
              to="/brand/INCI-screening"
            >
              Test compliance against other industry standards
            </Link>
          </div>
          <div className={styles.md}>
            <Link
              kind={LinkKind.Internal}
              variant={LinkVariant.NewDefault}
              to="/brand/discover?category_key=ingredient"
            >
              Discover and source more sustainable ingredients
            </Link>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

export default FooterRow;
