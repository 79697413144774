import { ReactNode } from 'react';
import { Link, LinkKind, TextKind } from 'design-system/components';
import { PATHS } from 'constants/index';
import checklistSvg from 'assets/illustrations/checklist.svg';
import highFiveSvg from 'assets/illustrations/high-five.svg';
import rocketSvg from 'assets/illustrations/rocket.svg';
import chatSvg from 'assets/illustrations/chat.svg';
import planetSvg from 'assets/illustrations/planet.svg';
import { BrandPolicySubmissionStatus } from 'design-system/data';
import links from 'constants/links';

export interface CompletePolicyStateType {
  svg: string;
  alt: string;
  links?: LinkType[];
  description: string;
  title: string;
  titleKind: string;
  subtitle?: string;
  showSubmitButton?: boolean;
  phosphorIconName?: string;
}

export interface SKUPolicyStateType {
  title: string;
  subtitle: string;
  link?: LinkType;
  phosphorIconName: string;
  actionButton?: ReactNode;
  secondaryActionButton?: ReactNode;
  footerElement?: ReactNode;
}

export interface CompletePolicyStateV2Type {
  title: string;
  description: ReactNode;
  link?: LinkType;
  phosphorIconName?: string;
  actionButton?: ReactNode;
  svg?: string;
  alt?: string;
}

export interface LinkType {
  to: string;
  text: string;
  linkKind?: string;
}

export const PolicySubmissionFlow = {
  NeedsInformation: (name: string): CompletePolicyStateType => {
    return {
      svg: checklistSvg,
      alt: 'checklist',
      links: [
        {
          to: PATHS.retailerBrandProducts,
          text: 'Finish uploading your SKUs',
        },
        {
          to: PATHS.retailerBrandProducts,
          text: 'View your SKUs',
        },
        {
          to: PATHS.retailerBrandRequirementGroups,
          text: 'Complete the attestation process',
        },
      ],
      description: '',
      title: `Next steps${name ? ` for ${name} ` : ''}`,
      titleKind: TextKind.DisplayXSMedium,
    };
  },
  Pass: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: rocketSvg,
      alt: 'rocket',
      description: `Would you like to submit your verification to ${retailerName} for review? If any requirements are still in a “pending” state, ${retailerName} will review them to ensure that they pass.`,
      title: `You completed all the required verifications for ${
        name ? `${name} at ` : ''
      }${retailerName}!`,
      titleKind: TextKind.Display2XSMedium,
      showSubmitButton: true,
    };
  },
  Fail: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: checklistSvg,
      alt: 'checklist',
      subtitle: 'Next steps',
      links: [
        {
          to: PATHS.retailerBrandRequirementGroups,
          text: 'Return to the attestation process',
        },
      ],
      description: `You didn’t pass all of the ${name} requirements. You will need to address this before you can submit your responses to ${retailerName}.`,
      title: `Something’s not quite right…`,
      titleKind: TextKind.DisplaySMMedium,
    };
  },
  Pending: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: chatSvg,
      alt: 'chat',
      description: `You will receive a notification when ${retailerName} has finished reviewing your responses.`,
      title: `Your attestations for ${name} are currently in review.`,
      titleKind: TextKind.Display2XSMedium,
    };
  },
  Approved: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: planetSvg,
      alt: 'planet',
      subtitle: 'Next steps',
      links: [
        {
          to: PATHS.retailerBrandSubmissions,
          text: `Read ${retailerName}’s comments`,
        },
        {
          to: PATHS.retailerBrandProducts,
          text: 'View your SKUs',
        },
      ],
      description: `You have completed all the requirements for ${name} and your submission to ${retailerName} has been approved.`,
      title: `Congratulations! You’re approved for ${name}.`,
      titleKind: TextKind.DisplayXSMedium,
    };
  },
  Rejected: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: checklistSvg,
      alt: 'checklist',
      subtitle: 'Next steps',
      links: [
        {
          to: PATHS.retailerBrandSubmissions,
          text: `View ${retailerName}’s comments`,
        },
      ],
      description: `You didn’t pass all of the ${name} requirements. Here’s what to do next.`,
      title: `Something’s not right…`,
      titleKind: TextKind.DisplaySMMedium,
    };
  },
};

export const OneOrMoreSKUsSubmissionFlow = {
  NeedsInformation: (
    name: string,
    retailerName: string
  ): SKUPolicyStateType[] => [
    {
      title: 'Work on your attestations',
      subtitle: `None of your SKUs qualify for ${name} yet. Learn more about the program requirements and complete the necessary attestations in order to submit to ${retailerName}.`,
      link: {
        to: PATHS.retailerBrandRequirementGroups,
        text: 'Attest',
      },
      phosphorIconName: 'ListChecks',
    },
  ],
  Pass: (
    name: string,
    retailerName: string,
    submitButton: ReactNode,
    viewDetailsButton: ReactNode,
    footerElement: ReactNode,
    submitText?: string
  ): SKUPolicyStateType[] => [
    {
      title: 'Continue your attestations',
      subtitle: `Some of your SKUs qualify for ${name}. Submit your work to ${retailerName} in order to request a review. Once you submit, you will not be able to edit your answers or complete more attestations until the retailer has reviewed your submission.`,
      link: {
        to: PATHS.retailerBrandRequirementGroups,
        text: 'Attest',
      },
      phosphorIconName: 'ListChecks',
    },
    {
      title: 'Submit your attestations',
      subtitle:
        submitText ||
        `Some of your SKUs qualify for ${name}. Submit your work to ${retailerName} in order to request a review. Once you submit, you will not be able to edit your answers or complete more attestations until the retailer has reviewed your submission.`,
      phosphorIconName: 'PaperPlaneTilt',
      actionButton: submitButton,
      secondaryActionButton: viewDetailsButton,
      footerElement,
    },
  ],
  Fail: {},
  Pending: (name: string, retailerName: string): SKUPolicyStateType[] => [
    {
      title: 'Your submission is pending',
      subtitle: `Your ${name} attestations have been submitted to ${retailerName}. You will not be able to edit your answers or complete more attestations until your submission has been reviewed. If you need to make a change, please contact support.`,
      phosphorIconName: 'SpinnerGap',
    },
  ],
  Approved: (
    name: string,
    retailerName: string,
    unlockButton: ReactNode,
    unlockText?: string
  ): SKUPolicyStateType[] => [
    {
      title: 'Your previous submission has been approved',
      subtitle: `Your completed ${name} attestations have been accepted by ${retailerName}! Any pending requirements have been reviewed; review your submissions page for any comments.`,
      link: {
        to: PATHS.retailerBrandSubmissions,
        text: 'Review',
      },
      phosphorIconName: 'SealCheck',
    },
    {
      title: 'Unlock and continue your attestations',
      subtitle:
        unlockText ||
        `If you want to add more SKUs or claims for ${retailerName} to review for the ${name} program, unlock your answers and continue your work.`,
      phosphorIconName: 'ListChecks',
      actionButton: unlockButton,
    },
  ],
  Rejected: (
    name: string,
    retailerName: string,
    unlockButton: ReactNode,
    unlockText?: string
  ): SKUPolicyStateType[] => [
    {
      title: 'Your submission was not accepted',
      subtitle: `Your completed ${name} attestations have been reviewed by ${retailerName}, but they found some issues; review your submissions page for more information.`,
      link: {
        to: PATHS.retailerBrandSubmissions,
        text: 'Review',
      },
      phosphorIconName: 'Warning',
    },
    {
      title: 'Unlock and continue your attestations',
      subtitle:
        unlockText ||
        `Unlock your attestations for the ${name} program so that you can make any necessary changes and resubmit to ${retailerName}.`,
      phosphorIconName: 'ListChecks',
      actionButton: unlockButton,
    },
  ],
  Empty: (name: string, retailerName: string): SKUPolicyStateType[] => [
    {
      title: 'Upload your SKUs',
      subtitle: `Upload your SKUs for ${retailerName} in order to start the attestation process for the ${name} program.`,
      phosphorIconName: 'UploadSimple',
      link: {
        to: PATHS.retailerBrandProducts,
        text: 'Add SKUs',
      },
    },
  ],
};

export const PolicySubmissionFlowV2 = {
  Initial: (
    policyName: string,
    retailerName: string,
    completeDescription?: string
  ): CompletePolicyStateV2Type => ({
    title: 'Work on your attestations',
    description: (
      <>
        None of your SKUs qualify for {policyName} at {retailerName}. Learn more
        about the program requirements and complete the necessary attestations
        in order to submit to {retailerName}.
        {completeDescription ? (
          <>
            <br />
            <br />
            {completeDescription}
          </>
        ) : (
          ''
        )}
      </>
    ),
    link: {
      to: PATHS.retailerBrandRequirementGroups,
      text: 'Attest',
    },
    phosphorIconName: 'ListChecks',
  }),
  NeedsInformation: (
    policyName: string,
    retailerName: string,
    completeDescription?: string
  ): CompletePolicyStateV2Type => ({
    title: 'Complete your attestations',
    description: (
      <>
        Some of your SKUs qualify for {policyName} at {retailerName}. Learn more
        about the program requirements and complete the necessary attestations
        in order to submit to {retailerName}.
        {completeDescription ? (
          <>
            <br />
            <br />
            {completeDescription}
          </>
        ) : (
          ''
        )}
      </>
    ),
    link: {
      to: PATHS.retailerBrandRequirementGroups,
      text: 'Attest',
    },
    phosphorIconName: 'ListChecks',
  }),
  Pass: (
    submitButton: ReactNode,
    policyName: string,
    retailerName: string,
    completeDescription?: string
  ): CompletePolicyStateV2Type => ({
    title: 'Submit your attestations',
    description: (
      <>
        Your attestations for {policyName} at {retailerName} are complete and
        you are eligible to submit your data to {retailerName} for approval.
        Once you submit, you will not be able to edit your answers or complete
        more attestations until the retailer has reviewed your submission.
        {completeDescription ? (
          <>
            <br />
            <br />
            {completeDescription}
          </>
        ) : (
          ''
        )}
      </>
    ),
    actionButton: submitButton,
    phosphorIconName: 'PaperPlaneTilt',
  }),
  Fail: (): CompletePolicyStateV2Type => ({
    title: '',
    description: ``,
    link: {
      to: PATHS.retailerBrandRequirementGroups,
      text: 'Attest',
    },
    phosphorIconName: '',
  }),
  Pending: (
    resourceHelpLink: string,
    retailerName: string
  ): CompletePolicyStateV2Type => ({
    title: 'Your submission is pending',
    description: (
      <>
        Your completed attestations have been submitted to {retailerName}. You
        will not be able to edit your answers or complete more attestations
        until the retailer has reviewed your submission. If you need to make a
        change, please contact{' '}
        <Link
          kind={LinkKind.External}
          href={links.mailtoSupport}
          style={{ fontSize: '1rem' }}
        >
          Support
        </Link>
        .
      </>
    ),
    link: {
      to: resourceHelpLink || links.helpDesk,
      text: 'View resources',
      linkKind: LinkKind.External,
    },
    phosphorIconName: 'SpinnerGap',
  }),
  Approved: (
    policyName: string,
    retailerName: string
  ): CompletePolicyStateV2Type => ({
    title: 'Your submission has been accepted',
    description: `You have completed all the requirements for ${policyName} at ${retailerName} and your submission to ${retailerName} has been approved.`,
    link: {
      to: PATHS.retailerBrandSubmissions,
      text: 'Take a look',
    },
    phosphorIconName: 'SealCheck',
    svg: highFiveSvg,
    alt: 'high five',
  }),
  Rejected: (
    policyName: string,
    retailerName: string
  ): CompletePolicyStateV2Type => ({
    title: "Something isn't right",
    description: `You did not pass all of the ${policyName} at ${retailerName} requirements. Review your Retailer Feedback from ${retailerName} to learn more.`,
    link: {
      to: PATHS.retailerBrandSubmissions,
      text: 'View submission',
    },
    phosphorIconName: 'Warning',
    svg: checklistSvg,
    alt: 'checklist',
  }),
  Empty: (retailerName: string): CompletePolicyStateV2Type => ({
    title: 'Upload your SKUs',
    description: `Upload your SKUs for ${retailerName} in order to start the attestation process for their program(s).`,
    phosphorIconName: 'UploadSimple',
    link: {
      to: PATHS.retailerBrandProducts,
      text: 'Add SKUs',
    },
  }),
};

export const hydratePolicySubmissionFlow = (
  submissionStatus: string,
  name: string,
  retailerName: string
) => {
  switch (submissionStatus) {
    case BrandPolicySubmissionStatus.NeedsInformation:
      return PolicySubmissionFlow.NeedsInformation(name);
    case BrandPolicySubmissionStatus.Pass:
      return PolicySubmissionFlow.Pass(name, retailerName);
    case BrandPolicySubmissionStatus.Fail:
      return PolicySubmissionFlow.Fail(name, retailerName);
    case BrandPolicySubmissionStatus.Pending:
    case BrandPolicySubmissionStatus.Ready:
      return PolicySubmissionFlow.Pending(name, retailerName);
    case BrandPolicySubmissionStatus.Approved:
      return PolicySubmissionFlow.Approved(name, retailerName);
    case BrandPolicySubmissionStatus.Rejected:
      return PolicySubmissionFlow.Rejected(name, retailerName);
    default:
      return null;
  }
};

export const hydrateSKUsFlow = (
  submissionStatus: string,
  someSkusQualify: boolean,
  name: string,
  retailerName: string,
  submitButton: ReactNode,
  unlockButton: ReactNode,
  noSkuData: boolean,
  viewDetailsButton: ReactNode,
  footerElement: ReactNode,
  policyCustomDescriptions: Record<string, string>
): SKUPolicyStateType[] => {
  if (noSkuData) {
    return OneOrMoreSKUsSubmissionFlow.Empty(name, retailerName);
  }

  switch (submissionStatus) {
    case BrandPolicySubmissionStatus.NeedsInformation:
    case BrandPolicySubmissionStatus.Pass:
      return someSkusQualify
        ? OneOrMoreSKUsSubmissionFlow.Pass(
            name,
            retailerName,
            submitButton,
            viewDetailsButton,
            footerElement,
            policyCustomDescriptions.submitDescription
          )
        : OneOrMoreSKUsSubmissionFlow.NeedsInformation(name, retailerName);
    // case BrandPolicySubmissionStatus.Fail:
    //   return OneOrMoreSKUsSubmissionFlow.Fail;
    case BrandPolicySubmissionStatus.Pending:
      return OneOrMoreSKUsSubmissionFlow.Pending(name, retailerName);
    case BrandPolicySubmissionStatus.Ready:
      return OneOrMoreSKUsSubmissionFlow.Pending(name, retailerName);
    case BrandPolicySubmissionStatus.Approved:
      return OneOrMoreSKUsSubmissionFlow.Approved(
        name,
        retailerName,
        unlockButton,
        policyCustomDescriptions.unlockDescription
      );
    case BrandPolicySubmissionStatus.Rejected:
      return OneOrMoreSKUsSubmissionFlow.Rejected(
        name,
        retailerName,
        unlockButton,
        policyCustomDescriptions.unlockDescription
      );
    default:
      return [];
  }
};

export const hydratePolicySubmissionFlowV2 = (
  submissionStatus: string,
  policyName: string,
  retailerName: string,
  percentComplete: number,
  completeDescription: string,
  resourceHelpLink: string,
  submitButton: ReactNode,
  noSkuData: boolean
) => {
  if (noSkuData) {
    return PolicySubmissionFlowV2.Empty(retailerName);
  }
  switch (submissionStatus) {
    case BrandPolicySubmissionStatus.NeedsInformation:
      switch (percentComplete) {
        case 0:
          return PolicySubmissionFlowV2.Initial(
            policyName,
            retailerName,
            completeDescription
          );
        default:
          return PolicySubmissionFlowV2.NeedsInformation(
            policyName,
            retailerName,
            completeDescription
          );
      }
    case BrandPolicySubmissionStatus.Pass:
      return PolicySubmissionFlowV2.Pass(
        submitButton,
        policyName,
        retailerName,
        completeDescription
      );
    case BrandPolicySubmissionStatus.Fail:
      // BRAND-9 - For Fail Status show same card as NeedsInformation
      return PolicySubmissionFlowV2.NeedsInformation(
        policyName,
        retailerName,
        completeDescription
      );
    case BrandPolicySubmissionStatus.Pending:
    case BrandPolicySubmissionStatus.Ready:
      return PolicySubmissionFlowV2.Pending(resourceHelpLink, retailerName);
    case BrandPolicySubmissionStatus.Approved:
      return PolicySubmissionFlowV2.Approved(policyName, retailerName);
    case BrandPolicySubmissionStatus.Rejected:
      return PolicySubmissionFlowV2.Rejected(policyName, retailerName);
    default:
      return null;
  }
};
