import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import {
  ButtonKind,
  BrandViewHeader,
  NoContent,
  NoData,
  NoDataVariants,
  Tab,
  TabGroup,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableFoot,
  Modal,
  ModalType,
  PaginationV2,
  PillColor,
  TabsAndTable,
} from 'design-system/components';
import {
  sortDirection,
  TableReducerAction,
  useTableSort,
  useInitialQueryParams,
  useSetUrlSearchParameters,
} from 'design-system/utils';

import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';
import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';
import RequirementSummaryRow from './RequirementSummaryRow';
import RequirementActionModalContentsContainer from '../shared/RequirementActionModalContents';
import SlideoutFooter from '../shared/RequirementActionModalContents/SlideoutFooter';
import Disclaimer from '../shared/Disclaimer';
import { tabStatuses } from './constants';
import { useProductScreeningResults } from './useProductScreeningResults';
import styles from './retailerBrandRequirementSummary.module.scss';

function RequirementSummaryView() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tableHeaderData = [
    {
      label: 'SKUs',
      value: 'consumerProductName',
    },
    {
      label: 'Status',
      value: 'screeningStatus',
    },
    {
      label: '',
      value: 'cta',
    },
  ];

  const { retailerBrand } = useApp();
  const { requirement_group_id, requirement_id, policy_id } = useParams();
  const baseUrl = PATHS.retailerBrandRequirementGroups;
  const {
    data: dataScreeningResults,
    loading: loadingScreeningResults,
    error: errorScreeningResults,
  } = useProductScreeningResults(policy_id, tabStatuses[activeTabIndex]);
  const {
    setToastOpen,
    setModalOpen,
    conditions,
    conditionsLoading,
    conditionsError,
    handleChange,
    triggerValidation,
    handleConditionValidation,
    modalOpen,
    handleSubmit,
    setCurrentProductId,
    actionSubmitLoading,
    canAttest,
    confirmClose,
  } = useRequirementActions();

  const [currentPage, setCurrentPage] = useState(
    useInitialQueryParams('page', 1)
  );
  const itemsPerPage = useInitialQueryParams('items', 10);
  const [currentRow, setCurrentRow] = useState(0);
  const [currentScreeningResult, setCurrentScreeningResult] = useState(null);
  const [previousActive, setPreviousActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);
  const hasLoadedSuccessfully =
    !loadingScreeningResults && !errorScreeningResults;
  const [tableState, dispatch] = useTableSort({
    initialSortByValue: tableHeaderData[0].value,
    initialSortAsc: false,
  });

  // TODO: update breadcrumbs with better data from BE
  const breadcrumbItems = [
    {
      text: 'Pillars',
      link: baseUrl,
    },
    {
      text: dataScreeningResults?.category || 'Retailer Requirements',
      link: `${baseUrl}/${requirement_group_id}/retailer_requirements`,
    },
    {
      text:
        dataScreeningResults?.policyRequirementName || 'Policy Requirements',
      link: `${baseUrl}/${requirement_group_id}/retailer_requirements/${requirement_id}/policy_requirements`,
    },
    {
      text: dataScreeningResults?.name,
      link: `${baseUrl}/${requirement_group_id}/retailer_requirements/${requirement_id}/policy_requirements/${policy_id}/summary`,
    },
  ];

  useSetUrlSearchParameters(tableState, currentPage, itemsPerPage);

  const statusQuantities = useMemo(() => {
    return dataScreeningResults?.screeningStatusCounts;
  }, [dataScreeningResults?.screeningStatusCounts]);

  const tabData = useMemo(() => {
    return [
      {
        id: 1,
        label: 'All Requirements',
        value: 'all_requirements',
        quantity: statusQuantities?.total,
        pillColor: PillColor.Purple,
      },
      {
        id: 2,
        label: 'Needs attention',
        value: 'incomplete',
        quantity:
          (statusQuantities?.needsInformation || 0) +
          (statusQuantities?.updating || 0),
        pillColor: PillColor.Warning,
      },
      {
        id: 3,
        label: 'Meets',
        value: 'pass',
        quantity: statusQuantities?.pass || 0,
        pillColor: PillColor.Success,
      },
      {
        id: 4,
        label: 'Does not meet',
        value: 'fail',
        quantity:
          (statusQuantities?.fail || 0) + (statusQuantities?.rejected || 0), // combine fail and rejected
        pillColor: PillColor.ErrorColor,
      },
      {
        id: 5,
        label: 'Pending',
        value: 'pending',
        quantity: statusQuantities?.pending || 0,
        pillColor: PillColor.Blue,
      },
      {
        id: 6,
        label: 'Not applicable',
        value: 'not_applicable',
        quantity: statusQuantities?.notApplicable || 0,
        pillColor: PillColor.Default,
      },
    ];
  }, [statusQuantities]);

  const currentTabData = useMemo(
    () => dataScreeningResults?.productScreeningResults,
    [dataScreeningResults?.productScreeningResults]
  );

  const orderedCurrentTabData = orderBy(
    currentTabData,
    tableState.sortBy,
    sortDirection(tableState)
  );

  const hasData = orderedCurrentTabData?.length > 0;

  const onAttestCtaClick = (productId) => {
    setCurrentProductId(productId);
  };

  const derivedRequirementNum = useMemo(() => {
    return (currentPage - 1) * itemsPerPage + currentRow;
  }, [currentPage, currentRow, itemsPerPage]);

  const modalNavNextLogic = (i) => {
    setNextActive(i < orderedCurrentTabData.length - 1);
  };
  const modalNavPrevLogic = (i) => {
    setPreviousActive(i > 0);
  };
  const modalNavLogic = (i) => {
    modalNavNextLogic(i);
    modalNavPrevLogic(i);
  };

  const onPrev = () => {
    setModalOpen(true);
    setToastOpen(false);
    onAttestCtaClick(orderedCurrentTabData[currentRow - 2]?.consumerProductId);
    setCurrentScreeningResult(orderedCurrentTabData[currentRow - 2]);
    setCurrentRow(currentRow - 1);
    modalNavLogic(currentRow - 2);
  };

  const onNext = () => {
    setModalOpen(true);
    setToastOpen(false);
    onAttestCtaClick(orderedCurrentTabData[currentRow]?.consumerProductId);
    setCurrentScreeningResult(orderedCurrentTabData[currentRow]);
    setCurrentRow(currentRow + 1);
    modalNavLogic(currentRow);
  };

  return (
    <main>
      <Modal
        show={modalOpen}
        title="Attest to conditions"
        type={ModalType.SlideOut}
        dataCyRoot="attestation-modal"
        cancelCtaLabel="Cancel"
        cancelCtaKind={ButtonKind.Tertiary}
        onClick={() => {
          setModalOpen(false);
          setToastOpen(false);
        }}
        confirmClose={confirmClose && canAttest}
        confirmCloseLabel="You have unsaved changes"
        confirmCloseDescription="Are you sure you want to exit? You will lose all unsaved changes if you do."
        confirmCloseIcon="FloppyDisk"
        showActionButtons
        footerSlot={
          <SlideoutFooter
            onNext={onNext}
            onPrev={onPrev}
            nextActive={nextActive}
            prevActive={previousActive}
            currentRequirementNum={derivedRequirementNum}
            totalItemCount={dataScreeningResults?.count}
            disabled={(confirmClose && canAttest) || actionSubmitLoading}
          />
        }
      >
        <RequirementActionModalContentsContainer
          conditions={conditions || []}
          loading={conditionsLoading}
          error={conditionsError}
          onChange={handleChange}
          triggerValidation={triggerValidation}
          handleConditionValidation={handleConditionValidation}
          canAttest={canAttest}
          doneCtaLabel={actionSubmitLoading ? 'Submitting...' : 'Attest'}
          onDone={handleSubmit}
          disableDone={
            conditionsLoading ||
            !conditions?.length ||
            actionSubmitLoading ||
            !canAttest
          }
          screeningResult={currentScreeningResult}
          brandId={retailerBrand?.consumer_brand?.id}
        />
      </Modal>
      <BrandViewHeader
        title={dataScreeningResults?.name}
        subTitle={dataScreeningResults?.description}
        breadcrumbsItems={breadcrumbItems}
        showExportButton={false}
        showFilterButton={false}
        onFilterClick={() => {}}
        onExportClick={() => {}}
        editableTitle={false}
        onTitleChange={() => {}}
        bannerColor=""
        bannerText=""
      />
      <TabsAndTable
        tabs={
          <TabGroup
            activeTabIndex={activeTabIndex}
            ariaLabelledBy="tabs-and-filter-header-subtitle"
          >
            {tabData.map((tab, index) => (
              <Tab
                pillColor={tab.pillColor}
                key={tab.value}
                chipValue={tab.quantity || 0}
                isActive={activeTabIndex === index}
                onClick={() => {
                  setActiveTabIndex(index);
                  setCurrentRow(0);
                }}
              >
                {tab.label}
              </Tab>
            ))}
          </TabGroup>
        }
        table={
          <div className={styles.table}>
            {/* LOADING STATE */}
            {loadingScreeningResults && !errorScreeningResults && (
              <NoContent loading />
            )}
            {/* ERROR STATE */}
            {errorScreeningResults && (
              <NoData
                hasErrorOccurred={!!errorScreeningResults}
                noContentMessage="Something went wrong. Please try again or contact support."
              />
            )}
            {/* NO DATA STATE */}
            {hasLoadedSuccessfully && !hasData && (
              <NoContent variant={NoDataVariants.Default} />
            )}
            {/* HYDRATED TABLE */}
            {hasLoadedSuccessfully && hasData && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeaderData?.map((data) => (
                        <TableHeadCell
                          key={data.value}
                          enableSort
                          active={data.value === tableState.sortBy}
                          direction={sortDirection(tableState, data.value)}
                          onClick={() =>
                            dispatch({
                              type: TableReducerAction.LabelClick,
                              sortBy: data.value,
                            })
                          }
                        >
                          {data.label}
                        </TableHeadCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderedCurrentTabData.map((productScreeningResult, i) => (
                      <RequirementSummaryRow
                        key={i}
                        isActive={currentRow === i + 1}
                        modalOpen={modalOpen}
                        productScreeningResult={productScreeningResult}
                        onClick={(id) => {
                          setModalOpen(true);
                          onAttestCtaClick(id);
                          setCurrentScreeningResult(productScreeningResult);
                          setCurrentRow(i + 1);
                          modalNavPrevLogic(i);
                          modalNavNextLogic(i);
                        }}
                      />
                    ))}
                  </TableBody>
                  <TableFoot bgWhite>
                    <TableRow>
                      <TableBodyCell colSpan={3}>
                        <PaginationV2
                          onNextPage={() => setCurrentPage(currentPage + 1)}
                          onPreviousPage={() => setCurrentPage(currentPage - 1)}
                          onPageSelect={setCurrentPage}
                          currentPage={dataScreeningResults?.page}
                          totalItemCount={dataScreeningResults?.count}
                          itemsPerPage={dataScreeningResults?.items}
                        />
                      </TableBodyCell>
                    </TableRow>
                  </TableFoot>
                </Table>
              </>
            )}
          </div>
        }
      />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand.retailer.disclaimer} />
      )}
    </main>
  );
}

export default RequirementSummaryView;
