import { convertSnakeToCamelCase } from 'design-system/utils';
import useApi from 'services/api/useApi';

const useRetailerPolicies = (retailerId, retailerConsumerBrandId) => {
  const {
    data,
    loading,
    error,
    refetch: refetchRetailerPolicies,
  } = useApi(`/api/v4/retailers/${retailerId}/policies`, {
    enabled: !!retailerId,
    param: {
      retailer_consumer_brand_id: retailerConsumerBrandId,
    },
  });

  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
    refetchRetailerPolicies,
  };
};

export default useRetailerPolicies;

export const useRetailer = (retailerId) => {
  const { data, loading, error } = useApi(`/api/v4/retailers/${retailerId}`, {
    enabled: !!retailerId,
  });

  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
};
