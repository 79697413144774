import {
  Link,
  LinkKind,
  LinkVariant,
  Text,
  TextKind,
  TextElement,
  Pill,
  PillColor,
} from 'design-system/components';
import { BrandColors } from 'design-system/data';
import { PATHS } from 'constants/index';

import NextStepsCTA from '../NextStepsCTA/NextStepsCTA';
import {
  DASHBOARD_NEXT_STEPS_CTA_TEXT,
  DASHBOARD_NEXT_STEPS_DEFAULT_TEXT,
  DASHBOARD_NEXT_STEPS_HEADINGS,
} from '../NextStepsCTA/constants';
import styles from '../retailerBrandDashboard.module.scss';

type PaymentCardType = {
  hasChargebee: boolean;
  handleChargebeeCheckout: { loading: any; call: () => void };
  skuCount: number;
  tierName: string;
};

const PaymentCard = ({
  hasChargebee,
  handleChargebeeCheckout,
  skuCount,
  tierName,
}: PaymentCardType) => {
  return hasChargebee ? (
    <NextStepsCTA
      cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.chargebee}
      phosphorIconName="HandCoins"
      descriptiveText={DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.chargebee}
      redirectPath="#"
      linkKind={LinkKind.Internal}
      ctaOnClick={() => handleChargebeeCheckout.call()}
      ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.chargebee}
      actionLoading={handleChargebeeCheckout.loading}
      secondaryAction={
        <Link
          kind={LinkKind.Internal}
          variant={LinkVariant.Secondary}
          to={PATHS.retailerBrandProducts}
        >
          <span className={styles.cta}>View SKUs</span>
        </Link>
      }
      footerElement={
        <div className={styles['sku-count']}>
          <Text
            kind={TextKind.TextSMSemibold}
            element={TextElement.P}
            color={BrandColors.Gray400}
          >
            Total SKUs
          </Text>
          <Pill color={PillColor.BrandSecondary}>{skuCount || '-'}</Pill>
          <Pill color={PillColor.BrandSecondary}>{tierName || '-'}</Pill>
        </div>
      }
    />
  ) : (
    <NextStepsCTA
      cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.payment}
      phosphorIconName="HandCoins"
      descriptiveText={DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.payment}
      redirectPath="mailto:support@noviconnect.com"
      linkKind={LinkKind.External}
      ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.payment}
    />
  );
};

export default PaymentCard;
