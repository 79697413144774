export const MAX_PRODUCTS = 1000;
export const MAX_UNIQUE_ID_LENGTH = 60;
export const MAX_PRODUCT_NAME_LENGTH = 1000;
export const MAX_UPC_NUMBER_LENGTH = 12;
export const PRODUCT_TYPE_BPC = 'Beauty and Personal Care';
export const fileTypeError =
  'Upload failed. Please try again with a .csv file.';
export const multipleFileError = `Upload failed. Please upload only one .csv file at a time.`;
export const noDataError = 'CSV Format Error: No data found in the file.';
export const maxProductsError = `Please note: Your CSV file contains more than the allowed ${MAX_PRODUCTS} products. Please reduce the amount of products and try again or reach out directly to support to handle additional uploads.`;

export const requiredColumns = [
  'Product Type',
  'Unique ID',
  'UPC',
  'Product Name',
  'Product Line',
  'Product Category',
  'Sales Channels',
  'Ingredients',
];
export const validProductTypes = [PRODUCT_TYPE_BPC, 'Food', 'Other'];
